/**
 * Video Replacement
 */

(function (d) {
    const videoReplacedEvent = new Event("video:replaced");

    function replaceVideo(wrapper, type, videoId) {
        var url = '';

        if (type === 'vimeo') {
            url = 'https://player.vimeo.com/video/' + videoId + '?autoplay=1&byline=0&badge=0&title=0&loop=1';
        } else {
            url = 'https://www.youtube.com/embed/' + videoId + '?autoplay=1&amp;rel=0&amp;showinfo=0&amp;&amp;autohide=1&amp;color=white';
        }

        wrapper.innerHTML = '<iframe width="1200" height="675" src="' + url + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        wrapper.style.display = 'block';

        wrapper.dispatchEvent(videoReplacedEvent);
    }

    d.querySelectorAll('.js-video').forEach(function (element) {
        var video = element.querySelector('.js-videoIframe');
        var image = element.querySelector('.js-videoImage');
        var playlistItems = element.querySelectorAll('.js-videoItem');

        image.addEventListener('click', function () {
            image.style.display = 'none';

            replaceVideo(video, video.dataset.videoType, video.dataset.videoId);
        });

        playlistItems.forEach(function (playlistItem) {
            playlistItem.addEventListener('click', function () {
                playlistItems.forEach(function (element) {
                    Util.removeClass(element, 'is-active');
                });

                Util.addClass(playlistItem, 'is-active');

                image.style.display = 'none';

                replaceVideo(video, playlistItem.dataset.videoType, playlistItem.dataset.videoId);
            });
        });
    });
}(document));
